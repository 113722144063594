<template>
  <v-container fluid>
    <v-form
      v-if="user"
      v-model="isFormValid"
      @submit.prevent="save">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <h3>{{ title }}</h3>
            </v-col>
            <v-col cols="6">
              <h4>Daten</h4>
              <v-select
                v-model="user.foa"
                :items="sexes"
                item-text="name"
                item-value="value"
                label="Geschlecht"
                :rules="[rules.sex]"
              ></v-select>
              <v-text-field
                label="Vorname*"
                v-model="user.firstname"
                :rules="[rules.required]"
              />
              <v-text-field
                label="Nachname*"
                v-model="user.lastname"
                :rules="[rules.required]"
              />
              <v-text-field
                label="E-Mail*"
                v-model="user.email"
                :rules="[rules.required, rules.email]"
              />
              <template v-if="isUserEditing">
                <v-text-field
                  label="Passwort"
                  v-model="user.password"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="[rules.requiredPassword]"
                  counter
                  @click:append="showPassword = !showPassword"
                  :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                />
                <v-text-field
                  label="Passwort wiederholen"
                  v-model="user.password_confirmation "
                  :type="showPasswordConfirmation ? 'text' : 'password'"
                  counter
                  @click:append="showPasswordConfirmation = !showPasswordConfirmation"
                  :append-icon="showPasswordConfirmation ? 'visibility' : 'visibility_off'"
                  :rules="[rules.password]"
                />
              </template>
              <v-dialog
                v-if="isUserEditing"
                v-model="showResetPasswordConfirm"
                persistent
                max-width="490"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on">
                    Zufälliges Passwort zusenden
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    Zufälliges Passwort zusenden
                  </v-card-title>
                  <v-card-text>
                    Möchten Sie für {{ user.firstname }} {{ user.lastname }} ein zufälliges Passwort setzen und dieses per E-Mail zusenden?
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="success"
                      @click="resetPassword"
                    >
                      Zufälliges Passwort senden
                    </v-btn>
                    <v-btn
                      @click="showResetPasswordConfirm = false"
                    >
                      Abbrechen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="6">
              <h4>Rechte</h4>
              <div
                v-for="(item, index) in user.rights"
                :key="index">
                <v-checkbox
                  v-model="user.rights[index].selected"
                  :label="item.label"
                  :value="true"
                  hide-details
                ></v-checkbox>
              </div>
            </v-col>
            <v-col cols="12">
              <v-btn
                class="mr-4"
                :disabled="isButtonDisabled"
                :loading="loading"
                color="success"
                type="submit">
                Speichern
              </v-btn>
              <v-dialog
                v-if="isUserEditing"
                v-model="showDeleteConfirm"
                persistent
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="error">
                    Löschen
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>Möchten Sie diesen Benutzer wirklich löschen?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="deleteUser"
                      :loading="deleting"
                    >
                      Ja
                    </v-btn>
                    <v-btn
                      color="red darken-1"
                      text
                      @click="showDeleteConfirm = false"
                    >
                      Nein
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import RightsAPI from '@/api/rights'
import UsersAPI from '@/api/users'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      isFormValid: false,
      showPassword: false,
      showPasswordConfirmation: false,
      showResetPasswordConfirm: false,
      showDeleteConfirm: false,
      deleting: false,
      loading: false,
      availableRights: [],
      defaultRights: [],
      rules: {
        required: (value) => !!value || 'Erforderlich',
        sex: (value) => (value === 1 || value === 0) || 'Ungültiger Wert',
        email: (value) => value.includes('@') || 'Ungültige E-Mail',
        requiredPassword: (value) => ((this.user.id === undefined && !!value) || this.user.id !== undefined) || 'Erforderlich',
        password: (value) => value === this.user.password || 'Passwörter sind nicht gleich',
      },
      sexes: [
        {
          name: 'Weiblich',
          value: 0,
        },
        {
          name: 'Männlich',
          value: 1,
        },
      ],
    }
  },
  created() {
    this.fetchUsers(Number(this.$route.params.companyId))
    this.fetchRights()
  },
  computed: {
    ...mapGetters({
      users: 'user/users',
    }),
    user() {
      if (!this.users) {
        return null
      }
      const userId = Number(this.$route.params.userId)
      const companyId = Number(this.$route.params.companyId)
      const currentUser = this.users.find((user) => user.id === userId)
      if (currentUser) {
        return currentUser
      }
      return {
        foa: 0,
        firstname: '',
        lastname: '',
        email: '',
        rights: this.defaultRights,
        company_id: companyId,
      }
    },
    isButtonDisabled() {
      return this.loading || !this.isFormValid
    },
    isUserEditing() {
      return !!this.user.id
    },
    title() {
      const userId = this.$route.params.userId
      if (userId === 'new') {
        return 'Mitarbeiter anlegen'
      }
      return 'Mitarbeiter bearbeiten'
    },
  },
  methods: {
    ...mapActions('user', ['fetchUsers']),
    save() {
      this.loading = true
      const apiCall = this.user.id
        ? UsersAPI.update(this.user)
        : UsersAPI.create(this.user)

      apiCall.then(() => {
        this.$toasted.success('Die Benutzerinformationen wurden erfolgreich gespeichert')
        const companyId = this.$route.params.companyId
        const userId = this.$route.params.userId
        if (userId === 'new') {
          this.$router.push(`/users/companies/${companyId}`)
        }
      }).catch((response) => {
        if (response.error === 422) {
          this.$toasted.error('Die angegebenen Daten waren ungültig')
        } else {
          this.$toasted.error('Hier stimmt etwas nicht. Bitte versuchen Sie es später erneut.')
        }
      }).finally(() => {
        this.loading = false
      })
    },
    deleteUser() {
      this.deleting = true

      UsersAPI.delete(this.user).then(() => {
        const companyId = this.$route.params.companyId
        this.$toasted.success('Erfolgreich gelöscht')
        this.showDeleteConfirm = false
        this.$router.push(`/users/companies/${companyId}`)
      }).catch((response) => {
        if (response.error === 404) {
          this.$toasted.error('Benutzer zum Löschen nicht gefunden')
        } else {
          this.$toasted.error('Hier stimmt etwas nicht. Bitte versuchen Sie es später erneut.')
        }
        this.showDeleteConfirm = false
      }).finally(() => {
        this.deleting = false
      })
    },
    fetchRights() {
      RightsAPI.getAvailableRights().then((response) => {
        this.availableRights = response
        let defaultRights = []
        Object.entries(this.availableRights).forEach((item) => {
          const right = {}
          right[item[0]] = {
            label: item[1],
            selected: 0,
          }
          defaultRights = { ...defaultRights, ...right }
        })
        this.defaultRights = defaultRights
      })
    },
    resetPassword() {
      this.loading = true

      UsersAPI.resetPassword(this.user).then(() => {
        this.$toasted.success('Das neue Passwort wurde versendet')
      }).catch(() => {
        this.$toasted.error('Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut')
      }).finally(() => {
        this.loading = false
        this.showResetPasswordConfirm = false
      })
    },
  },
}
</script>
